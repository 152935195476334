<template>
  <div class="activity-organization-wrap">
      <div class="data-box">
          <div class="data-box-head">
            {{topTips}}
          </div>
          <div class="data-box-body">
              <div class="body-div">
                <div class="body-div-title">{{title}}</div>
                <div class="body-div-item" v-for="(item,index) in resultKeyValueList" :key="index">
                    <div class="span-key span-line">{{ item.key }}</div>
                    <div class="span-value">
                        <span>{{item.value}}</span>
                        <a v-if="item.phone" :href="'tel:' + item.phone" class="iconfont icon-dianhua1"></a>
                    </div>
                </div>
              </div>
              <p class="bottom-tips">{{bottomTips}}</p>
              <div class="data-box-foot" v-if="title == '活动信息'">
                <van-button round block type="info" @click="toLogin" ref="checkInBtn">
                    查看详情
                </van-button>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import {noticeContent} from "@/api/index"
export default {
    data () {
        return {
            resultKeyValueList: [
            ],
            topTips: "",
            title: "",
            bottomTips: ""
        }
    },
    mounted() {
        let that = this
        that.getNoticeContent()
    },
    methods: {
        toLogin() {
            console.log("跳转到登录页")
        },
        getNoticeContent() {
            let that = this
            let params = {
                type: 1,
                acyId: 63
            }
            noticeContent(params).then(res => {
                that.$tool.resValidation(res, () => {
                    let {data} = res
                    console.log(data)
                    let keys = Object.keys(data.content)
                    keys.forEach(keys2 => {
                        let value = ""
                        let phone = ""
                        if(Object.prototype.toString.call(data.content[keys2]) === '[object Object]') {
                            value = data.content[keys2].name
                            phone = data.content[keys2].phone
                        }else {
                            value = data.content[keys2]
                        }
                        that.resultKeyValueList.push({"key": keys2, "value": value, "phone": phone})
                    })
                    that.topTips = data.topContent
                    that.title = data.theme
                    that.bottomTips = data.bottomContent
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.span-value {
    display: flex;
    justify-content: space-between;
}
</style>